<template>
  <div>
    <div v-for="(status, indexStatus) in patientsByStatusList" :key="indexStatus">
      <v-subheader class="px-0 font-weight-bold">{{ $t(status.statusLevelTranslation) }}</v-subheader>
      <div v-if="status.patients.length > 0">
        <v-row dense>
          <v-col v-for="(patient, indexPatient) in status.patients" :key="indexPatient" cols="12" lg="6" xl="4">
            <div class="patient-container">
              <v-icon class="patient-icon" small :color="getStatusLevelColor(status.statusLevelCode)"
                >{{ getStatusLevelIcon(status.statusLevelCode) }}
              </v-icon>
              <img
                v-if="status.statusLevelCode === statusLevels.CH"
                :src="changeEssity"
                class="pt-2"
                alt="change-incontinence-icon"
                width="16"
                height="16"
              />
              <span class="mt-2 text--secondary text-body-2">
                {{ getAlertDatetime(patient.alert) }}
              </span>
              <span class="text-body-1 text--primary font-weight-bold">
                {{ patient.patient.lastName }}, {{ patient.patient.firstName }}
              </span>
              <span v-if="patient.patient.roombed.room" class="mt-2 text--secondary text-body-2">
                {{ $t('roombed') }} : {{ patient.patient.roombed.room }}-{{ patient.patient.roombed.bed }}
              </span>
              <span class="mr-4 text-right" :class="{ grow: $vuetify.breakpoint.mdAndDown }">
                <v-menu offset-y>
                  <template #activator="{ on }">
                    <v-btn :ref="`actions_${patient.patient.patientId}`" icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      :ref="`viewDetails_${patient.patient.patientId}`"
                      @click="viewPatientMonitoringDetail(patient.patient.patientId, patient.alert.activityId)"
                    >
                      <v-list-item-icon><v-icon>mdi-monitor-eye</v-icon></v-list-item-icon>
                      <v-list-item-title>{{ $t('details') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :ref="`resolve_${patient.patient.patientId}`"
                      @click="resolvePatientMonitoringAlert(patient.alert.patientMonitoringAlertId)"
                    >
                      <v-list-item-icon><v-icon>mdi-check</v-icon></v-list-item-icon>
                      <v-list-item-title>{{ $t('patientMonitoringAlert.resolved') }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </span>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col class="mt-2 text--secondary text-body-2">{{ $t('patientMonitoringAlert.noInformation') }}</v-col>
        </v-row>
      </div>
      <v-divider v-if="indexStatus !== patientsByStatusList.length - 1" class="my-4"></v-divider>
    </div>
  </div>
</template>

<script>
import patientMonitoringAlertService from '@/services/patientMonitoringAlertService';
import translationMixin, { LanguageVue } from '@/translationMixin';
import { format } from 'date-fns';
import { getFormattedTime, isDateToday } from '@/utils/dateUtils';
import { StatusLevels } from '../PatientMonitoring/constants';

export default {
  name: 'PatientMonitoringAlertContentRealtime',
  components: {},
  mixins: [translationMixin],

  props: {
    patientsByStatusList: {
      type: Array,
      required: true,
    },
    statusLevelsForActivityType: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      statusLevels: StatusLevels,
      changeEssity: '/static/incontinenceicon/change_essity.svg',
    };
  },
  methods: {
    getAlertDatetime(alert) {
      const timeString = getFormattedTime(alert.dataDatetime, LanguageVue.getLanguage());
      if (isDateToday(alert.dataDatetime)) {
        return timeString;
      }
      const date = new Date(alert.dataDatetime);
      const dateString = format(date, 'yyyy-MM-dd');
      return `${dateString} ${timeString}`;
    },
    getStatusLevelColor(statusLevelCode) {
      let obj = this.statusLevelsForActivityType.find((item) => item.statusLevelCode === statusLevelCode);
      if (obj) {
        return obj.iconColor;
      }
      return 'black';
    },
    getStatusLevelIcon(statusLevelCode) {
      let obj = this.statusLevelsForActivityType.find((item) => item.statusLevelCode === statusLevelCode);
      if (obj) {
        return obj.icon;
      }
      return '';
    },
    viewPatientMonitoringDetail: function (patientId, activityId) {
      this.$router.push({
        name: 'PatientMonitoringGrid',
        params: { patientId: patientId, detailType: String(activityId) },
      });
    },
    resolvePatientMonitoringAlert: async function (patientMonitoringAlertId) {
      await patientMonitoringAlertService.resolveMonitoringAlert(patientMonitoringAlertId, { resolved: true });
      this.$emit('reset-timer');
    },
  },
};
</script>

<style scoped>
.patient-container {
  display: flex;
  align-items: baseline;
  column-gap: 12px;
}
</style>
