<template>
  <v-container>
    <v-row align="center">
      <v-col>
        <PageTitle :title="$t('patientMonitoringAlert.title')" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="searchValue"
          dense
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          clearable
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-btn ref="filtersIcon" icon @click="showFilterSheet = !showFilterSheet">
          <v-icon v-if="filters.statusLevelIds.length === 0 && filters.cohortIds.length === 0">mdi-filter</v-icon>
          <v-icon v-else color="primary">mdi-filter-check</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="(panel, indexPanel) in panelsList" :key="indexPanel">
            <v-expansion-panel-header>
              <PatientMonitoringAlertHeaderRealtime
                :patients-by-status-list="panel.dataList"
                :status-levels-for-activity-type="panel.statusList"
                :title="panel.title"
              ></PatientMonitoringAlertHeaderRealtime>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <PatientMonitoringAlertContentRealtime
                :patients-by-status-list="panel.dataList"
                :status-levels-for-activity-type="panel.statusList"
                @reset-timer="resetTimer"
              ></PatientMonitoringAlertContentRealtime>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-navigation-drawer v-model="showFilterSheet" fixed temporary right touchless>
      <v-card flat>
        <v-card-title>{{ $t('filters') }}</v-card-title>
        <v-card-text>
          <PatientMonitoringAlertFilterRealtime @filter="onMonitoringFilter" />
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import patientMonitoringAlertService from '@/services/patientMonitoringAlertService';
import translationMixin from '@/translationMixin';
import PatientMonitoringAlertFilterRealtime from './PatientMonitoringAlertFilterRealtime';
import PatientMonitoringAlertContentRealtime from './PatientMonitoringAlertContentRealtime';
import PatientMonitoringAlertHeaderRealtime from './PatientMonitoringAlertHeaderRealtime';
import { containsString } from '@/utils/stringUtils';
import { ActivityTypes, StatusLevels } from '../PatientMonitoring/constants';

export default {
  name: 'PatientMonitoringAlert',
  components: {
    PatientMonitoringAlertFilterRealtime,
    PatientMonitoringAlertContentRealtime,
    PatientMonitoringAlertHeaderRealtime,
  },
  mixins: [translationMixin],
  props: {},
  data() {
    return {
      timer: null,
      showFilterSheet: false,
      filters: {
        statusLevelIds: [],
        cohortIds: [],
      },
      searchValue: '',
      patientMonitoringAlerts: [],

      activityTypes: [ActivityTypes.FDE, ActivityTypes.INC],
      statusLevelsFDE: [
        {
          statusLevelCode: StatusLevels.AL,
          statusLevelTranslation: 'patientMonitoringAlert.fall',
          icon: 'mdi-alert-circle',
          iconColor: 'red',
        },
      ],
      statusLevelsINC: [
        {
          statusLevelCode: StatusLevels.ERR,
          statusLevelTranslation: 'patientMonitoringAlert.error',
          icon: 'mdi-alert',
          iconColor: 'red',
        },
        {
          statusLevelCode: StatusLevels.CH,
          statusLevelTranslation: 'patientMonitoringAlert.change',
        },
        {
          statusLevelCode: StatusLevels.WE,
          statusLevelTranslation: 'patientMonitoringAlert.we',
          icon: 'mdi-check-circle',
          iconColor: '#F2BA4C',
        },
      ],
    };
  },
  computed: {
    fallDetectionAlerts() {
      return this.filterActivityTypeData(ActivityTypes.FDE, this.statusLevelsFDE);
    },
    incontinenceAlerts() {
      return this.filterActivityTypeData(ActivityTypes.INC, this.statusLevelsINC);
    },
    panelsList() {
      return [
        { dataList: this.fallDetectionAlerts, statusList: this.statusLevelsFDE, title: 'fallDetection' },
        { dataList: this.incontinenceAlerts, statusList: this.statusLevelsINC, title: 'incontinence' },
      ];
    },
  },
  async created() {
    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    getPatientMonitoringAlerts: async function () {
      this.patientMonitoringAlerts = await patientMonitoringAlertService.getPatientMonitoringAlerts();

      this.patientMonitoringAlerts.forEach((patientMonitoringAlert, ind) => {
        if (patientMonitoringAlert.activityType === ActivityTypes.INC) {
          this.statusLevelsINC.forEach((statusLevel) => {
            const exists = patientMonitoringAlert.patientsByStatus.some(
              (item) => item.statusLevelCode === statusLevel.statusLevelCode
            );

            if (!exists) {
              this.patientMonitoringAlerts[ind].patientsByStatus.push({
                statusLevelId: null,
                statusLevelCode: statusLevel.statusLevelCode,
                patients: [],
              });
            }
          });
        }
      });
    },
    filterActivityTypeData: function (activityTypeCode, statusLevelsForActivityType) {
      let result = [];
      const items = this.patientMonitoringAlerts.find((item) => item.activityType === activityTypeCode);
      if (!items) {
        result = [
          {
            statusLevelId: null,
            statusLevelCode: '',
            statusLevelTranslation: '',
            patientsCount: 0,
            patients: [],
          },
        ];
        return result;
      }

      items.patientsByStatus?.forEach((i) => {
        let validStatusLevel = true;
        if (this.filters.statusLevelIds.length > 0) {
          validStatusLevel = false;
          let statusLevelInFilter = this.filters.statusLevelIds.find((x) => x === i.statusLevelId);
          if (statusLevelInFilter) {
            validStatusLevel = true;
          }
        }

        if (validStatusLevel) {
          let filteredPatients = this.filterPatients(i.patients);
          result.push({
            statusLevelId: i.statusLevelId,
            statusLevelCode: i.statusLevelCode,
            statusLevelTranslation: statusLevelsForActivityType.find(
              (status) => status.statusLevelCode === i.statusLevelCode
            ).statusLevelTranslation,
            patientsCount: filteredPatients.length,
            patients: filteredPatients,
          });
        } else {
          result.push({
            statusLevelId: i.statusLevelId,
            statusLevelCode: i.statusLevelCode,
            statusLevelTranslation: statusLevelsForActivityType.find(
              (status) => status.statusLevelCode === i.statusLevelCode
            ).statusLevelTranslation,
            patientsCount: 0,
            patients: [],
          });
        }
      });
      return result;
    },
    filterPatients: function (patients) {
      return patients
        .filter((patient) => {
          if (!this.searchValue) {
            return true;
          }
          let name = `${patient.patient.lastName}, ${patient.patient.firstName}`;
          let roombed;
          if (patient.patient.roombed) {
            roombed = `${patient.patient.roombed.room}-${patient.patient.roombed.bed}`;
          }
          return containsString(name, this.searchValue) || containsString(roombed, this.searchValue);
        })
        .filter((patient) => {
          if (this.filters.cohortIds.length === 0) {
            return true;
          }
          let exists = false;
          patient.patient.cohortIds?.forEach((cohort) => {
            if (!exists) {
              exists = this.filters.cohortIds.some((item) => item === cohort);
            }
          });
          return exists;
        })
        .sort((a, b) => new Date(b.alert.dataDatetime) - new Date(a.alert.dataDatetime));
    },
    onMonitoringFilter: function (filters) {
      this.showFilterSheet = false;
      this.filters = filters;
    },
    startTimer() {
      this.getPatientMonitoringAlerts();
      this.timer = setInterval(this.getPatientMonitoringAlerts, 15000);
    },

    stopTimer() {
      clearInterval(this.timer);
    },

    resetTimer() {
      this.stopTimer();
      this.startTimer();
    },
  },
};
</script>
