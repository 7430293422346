<template>
  <v-row align="center">
    <v-col cols="auto mr-4">
      <h3>{{ $t(title) }}</h3>
    </v-col>
    <v-col v-for="(statusLevel, i) in statusLevelsForActivityType" :key="i" cols="auto" class="px-2">
      <v-icon ref="statusLevelIcon" medium :color="getStatusLevelColorByCount(statusLevel.statusLevelCode)">
        {{ statusLevel.icon }}
      </v-icon>

      <span v-if="statusLevel.statusLevelCode === statusLevels.CH">
        <img
          :src="getStatusChangeIconColor(statusLevel.statusLevelCode)"
          class="mr-1 mb-n1"
          alt="change-incontinence-icon"
        />
      </span>

      <span ref="statusLevelCount">
        {{ getStatusCount(statusLevel.statusLevelCode) }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
import translationMixin from '@/translationMixin';
import { StatusLevels } from '../PatientMonitoring/constants';

export default {
  name: 'PatientMonitoringAlertHeaderRealtime',
  components: {},
  mixins: [translationMixin],

  props: {
    patientsByStatusList: {
      type: Array,
      required: true,
    },
    statusLevelsForActivityType: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      statusLevels: StatusLevels,
      changeEssity: '/static/incontinenceicon/change_essity.svg',
      changeGreyEssity: '/static/incontinenceicon/change_essity_grey.svg',
    };
  },

  methods: {
    getStatusCount(statusLevelCode) {
      let obj = this.patientsByStatusList.find((item) => item.statusLevelCode === statusLevelCode);
      if (obj) {
        return obj.patientsCount;
      }
      return 0;
    },
    getStatusLevelColor(statusLevelCode) {
      let obj = this.statusLevelsForActivityType.find((item) => item.statusLevelCode === statusLevelCode);
      if (obj) {
        return obj.iconColor;
      }
      return 'black';
    },
    getStatusLevelColorByCount(statusLevelCode) {
      let patientsCount = this.getStatusCount(statusLevelCode);
      if (patientsCount === 0) {
        return 'gray';
      }
      return this.getStatusLevelColor(statusLevelCode);
    },

    getStatusChangeIconColor(statusLevelCH) {
      let patientsCount = this.getStatusCount(statusLevelCH);
      if (patientsCount === 0) {
        return this.changeGreyEssity;
      } else {
        return this.changeEssity;
      }
    },
  },
};
</script>

<style scoped></style>
