import userService from './services/userService';

class WSHandler {
  constructor() {
    this.explicitlyClosed = false;
    this.retried = 0;
    this.status = 'CLOSED';

    this.ws = null;

    this.options = {
      autoReconnect: true,
    };
    this.hooks = [];
  }

  async _init() {
    this.status = 'CONNECTING';

    const userToken = await userService.getUserToken();

    const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
    const url = `${protocol}://${window.location.host}/ws/notifications?token=${encodeURIComponent(userToken)}`;

    this.ws = new WebSocket(url);

    const self = this;

    this.ws.onopen = () => {
      self.status = 'OPEN';
      for (let index = 0; index < self.hooks.length; index++) {
        const element = self.hooks[index];
        if (self.hooks.find((h) => h.event.topic === element.event.topic) === element) {
          self.addSubscription(element.event);
        }
      }
    };

    this.ws.onclose = () => {
      self.status = 'CLOSED';
      self.ws = null;

      if (!self.explicitlyClosed && self.options.autoReconnect) {
        const retries = 5;
        const delay = 1000;

        self.retried += 1;

        if (self.retried < retries)
          setTimeout(() => {
            if (self.status === 'CLOSED') {
              self._init();
            }
          }, delay);
      }
    };

    this.ws.onmessage = (e) => {
      self.handleMessage(e);
    };
  }

  handleMessage(e) {
    const data = JSON.parse(e.data ?? '{}');
    const callbacks = this.hooks.filter((h) => h.event.topic === data.topic).map((h) => h.handler);
    callbacks.forEach((cb) => cb(data));
  }

  open() {
    if (this.status !== 'CLOSED') {
      return;
    }
    this._init();
  }

  close() {
    if (!this.ws) {
      return;
    }
    this.explicitlyClosed = true;
    this.ws.close(1000);
  }

  addSubscription(event) {
    if (this.status === 'OPEN') {
      this.ws.send(JSON.stringify({ command: 'SUBSCRIBE', data: event }));
    }
  }

  removeSubscription(event) {
    if (this.status === 'OPEN') {
      this.ws.send(JSON.stringify({ command: 'UNSUBSCRIBE', data: event }));
    }
  }

  subscribe(event, handler) {
    this.hooks.push({ event: event, handler: handler });
    this.addSubscription(event);
    if (this.status === 'CLOSED') {
      this.open();
    }
  }

  unsubscribe(event, handler) {
    this.hooks = this.hooks.filter((h) => !(event.topic === h.event.topic && handler === h.handler));
    if (!this.hooks.some((h) => h.event.topic === event.topic)) {
      this.removeSubscription(event);
    }
    if (this.status === 'OPEN' && this.hooks.length === 0) {
      this.close();
    }
  }
}

/** @type WSHandler | null */
let handlerInstance = null;

function doSubscribe(event, handler) {
  if (!handlerInstance) {
    handlerInstance = new WSHandler();
  }

  handlerInstance.subscribe(event, handler);
}

function doUnsubscribe(event, handler) {
  if (!handlerInstance) {
    return;
  }

  handlerInstance.unsubscribe(event, handler);
}

export default {
  data() {
    return {
      $wsSubscriptions: {
        patientIotRealtime: false,
      },
    };
  },
  beforeDestroy() {
    if (this.$data.$wsSubscriptions.patientIotRealtime) {
      doUnsubscribe({ topic: 'PATIENT_IOT_REALTIME' }, this.onPatientIotRealtimeData);
    }
  },
  methods: {
    subscribeToPatientIotRealtime() {
      if (!this.$data.$wsSubscriptions.patientIotRealtime) {
        doSubscribe({ topic: 'PATIENT_IOT_REALTIME' }, this.onPatientIotRealtimeData);
        this.$data.$wsSubscriptions.patientIotRealtime = true;
      }
    },
    onPatientIotRealtimeData() {},
  },
};
