export default {
  en: {
    'patientMonitoringAlert.title': 'Alerts Monitoring',
    'patientMonitoringAlert.fall': 'Fall',
    'patientMonitoringAlert.noInformation': 'No information to display',
    'patientMonitoringAlert.change': 'Consider change',
    'patientMonitoringAlert.error': 'Error',
    'patientMonitoringAlert.we': 'Some urine',
    'patientMonitoringAlert.dr': 'OK',
    'patientMonitoringAlert.resolved': 'Resolved',
  },
  fr: {
    'patientMonitoringAlert.title': 'Surveillance alertes',
    'patientMonitoringAlert.fall': 'Chute',
    'patientMonitoringAlert.noInformation': 'Aucune information à afficher',
    'patientMonitoringAlert.change': 'Changement',
    'patientMonitoringAlert.error': 'Erreur',
    'patientMonitoringAlert.we': "Présence d'urine",
    'patientMonitoringAlert.dr': 'OK',
    'patientMonitoringAlert.resolved': 'Résolue',
  },
};
