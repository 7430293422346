import api from './baseApiService';
export class PatientMonitoringAlertService {
  async getPatientMonitoringAlerts() {
    return await api.get('monitoringalertrealtime');
  }

  async resolveMonitoringAlert(patientMonitoringAlertId, data) {
    return await api.put(`monitoringalert/${patientMonitoringAlertId}/resolve/`, data);
  }
}

export default new PatientMonitoringAlertService();
