<template>
  <v-container>
    <!-- <ConfirmationModal
      :question="$t('deletePatientQuestion')"
      :show="showDelete"
      :title="$t('delete')"
      @cancel="showDelete = false"
      @ok="deleteSelectedResident"
    /> -->

    <ErrorModal :error="error" @close-error-modal="error = null" />

    <template v-if="activityResidentId == null && communicatorResidentId == null">
      <v-row>
        <v-col>
          <div class="page-title">
            {{ $t('patients') }}
          </div>
        </v-col>
      </v-row>

      <v-row v-if="$can(permissions.actions.add, permissions.subjects.patient)">
        <v-col>
          <div>
            <v-btn ref="addPatient" color="primary" @click="editResidentAction(null)">
              {{ $t('add') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <WaitModal :show="showWaitModal" />

      <v-row>
        <v-col>
          <DataGrid
            :items="residents"
            :columns="columns"
            :show-select="false"
            grid-name="patient_grid"
            :items-per-page="25"
            :data-loading="showWaitModal"
          >
            <template #item.active="{ item }">
              <v-simple-checkbox v-model="item.active" disabled />
            </template>
            <template #item.actions="{ item }">
              <v-menu>
                <template #activator="{ on }">
                  <v-btn :ref="`actions_${item.id}`" icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <template v-if="$can(permissions.actions.change, permissions.subjects.patient)">
                    <v-list-item :ref="`editPatient_${item.id}`" @click="editResidentAction(item.id)">
                      <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                      <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="hasAssignPermissions" />
                  </template>
                  <v-list-item
                    v-if="$can(permissions.actions.view, permissions.subjects.patientMonitoring) && item.active"
                    :ref="`viewPatient_${item.id}`"
                    @click="viewPatientMonitoringDetail(item.id)"
                  >
                    <v-list-item-icon><v-icon>mdi-card-account-details-outline</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('patientMonitoring') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="$can(permissions.actions.change, permissions.subjects.patientActivityProtocol)"
                    :ref="`editActivities_${item.id}`"
                    @click="goToUserActivitiesSelector(item.id)"
                  >
                    <v-list-item-icon><v-icon>mdi-clipboard-pulse-outline</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('activitiesProtocols') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="$can(permissions.actions.change, permissions.subjects.patientHealthWorker)"
                    :ref="`editHealthcareProfessionals_${item.id}`"
                    @click="setHealthcareProfessionalResidentId(item.id)"
                  >
                    <v-list-item-icon><v-icon>mdi-stethoscope</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('healthcareProfessionals') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="$can(permissions.actions.change, permissions.subjects.patientIotDevice)"
                    :ref="`editIots_${item.id}`"
                    @click="setCommunicatorResidentId(item.id)"
                  >
                    <v-list-item-icon><v-icon>mdi-devices</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('iot') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="$can(permissions.actions.change, permissions.subjects.patientCohort)"
                    :ref="`editCohorts_${item.id}`"
                    @click="setCohortResidentId(item.id)"
                  >
                    <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('assignCohort') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="$can(permissions.actions.change, permissions.subjects.resource)"
                    :ref="`editDoc_${item.id}`"
                    @click="setDocResidentId(item.id)"
                  >
                    <v-list-item-icon><v-icon>mdi-file-document</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('assignResource') }}</v-list-item-title>
                  </v-list-item>

                  <!-- <template v-if="$can(permissions.actions.delete, permissions.subjects.patient)">
                    <v-divider
                      v-if="$can(permissions.actions.change, permissions.subjects.patient) || hasAssignPermissions"
                    />

                    <v-list-item :ref="`deletePatient_${item.id}`" @click="deleteAction(item.id)">
                      <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                      <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                    </v-list-item>
                  </template> -->
                </v-list>
              </v-menu>
            </template>
          </DataGrid>
        </v-col>
      </v-row>
    </template>

    <PatientModal :patient-id="selectedResidentId" :show="showEditModal" @close="residentModalClose" />

    <PatientHealthcareProfessionals
      :patient-id="selectedResidentId"
      :show.sync="showAssignHealthcareProfessionalsModal"
      :patient-info="selectedPatientInfo"
      @saved="init"
    />
    <PatientIOTDevices
      :patient-id="selectedResidentId"
      :show.sync="showAssignIOTModal"
      :patient-info="selectedPatientInfo"
      @saved="init"
    />
    <PatientCohorts
      :patient-id="selectedResidentId"
      :show.sync="showAssignCohortModal"
      :patient-info="selectedPatientInfo"
      @saved="init"
    />

    <PatientActivityModal
      :patient-id="selectedResidentId"
      :show.sync="showAssignActivityModal"
      :patient-info="selectedPatientInfo"
      @saved="init"
    />

    <ResourcesAssignModal
      :patient-id="selectedResidentId"
      :show.sync="showAssignDocModal"
      :patient-info="selectedPatientInfo"
      @saved="init"
    />
  </v-container>
</template>

<script>
import virtuoseMixin from '@/virtuoseMixin';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import permissionMixin from '@/auth/permissionMixin';

import PatientModal from './PatientModal';
import PatientHealthcareProfessionals from '@/components/HealthcareProfessional/PatientHealthcareProfessionals';
import PatientIOTDevices from '@/components/Iot/PatientIOTDevices';
import PatientCohorts from '@/components/Cohorts/PatientCohorts';
import ResourcesAssignModal from '@/components/Resources/ResourcesAssignModal';
import DataGrid from '@/components/DataGrid';
import PatientActivityModal from '@/components/Activity/PatientActivity/PatientActivityModal';

export default {
  name: 'PatientGrid',
  components: {
    PatientModal,
    PatientHealthcareProfessionals,
    PatientIOTDevices,
    PatientCohorts,
    DataGrid,
    PatientActivityModal,
    ResourcesAssignModal,
  },
  mixins: [translationMixin, virtuoseMixin, accessibility, permissionMixin],

  props: {},

  data() {
    return {
      error: null,
      showWaitModal: false,
      residents: [],
      communicatorResidentId: null,
      showAssignHealthcareProfessionalsModal: false,
      showAssignIOTModal: false,
      showAssignActivityModal: false,
      showAssignCohortModal: false,
      showAssignDocModal: false,
      showEditModal: false,
      selectedResidentId: null,
      activityResidentId: null,
      showDelete: false,
    };
  },
  computed: {
    selectedPatientInfo() {
      return this.residents.find((x) => x.id === this.selectedResidentId) ?? {};
    },
    columns() {
      let columns = [
        {
          text: 'lastName',
          value: 'lastName',
          filterable: true,
        },
        {
          text: 'firstName',
          value: 'firstName',
          filterable: true,
        },
        {
          text: 'mrn',
          value: 'mrn',
          filterable: true,
        },
        {
          text: 'nasm',
          value: 'nasm',
          filterable: true,
          visible: false,
        },
        {
          text: 'gender',
          value: 'gender',
          filterable: true,
          visible: false,
        },
        {
          text: 'language',
          value: 'language',
          filterable: true,
          visible: false,
        },
        {
          text: 'birthDate',
          value: 'birthDate',
          filterable: true,
          visible: false,
        },
        {
          text: 'deathDate',
          value: 'deathDate',
          filterable: true,
          visible: false,
        },
        {
          text: 'facility',
          value: 'facility',
          filterable: true,
          visible: false,
        },
        {
          text: 'roombed',
          value: 'roombed',
          filterable: true,
        },
        {
          text: 'admitDate',
          value: 'startDate',
          filterable: true,
          visible: false,
        },
        {
          text: 'active',
          value: 'active',
          filterable: true,
        },
        {
          text: 'address',
          value: 'address',
          filterable: true,
        },
        {
          text: 'city',
          value: 'city',
          filterable: true,
        },
        {
          text: 'province',
          value: 'province',
          filterable: true,
        },
        {
          text: 'country',
          value: 'country',
          filterable: true,
          visible: false,
        },
        {
          text: 'postalCode',
          value: 'postalCode',
          filterable: true,
          visible: false,
        },
        {
          text: 'phone',
          value: 'phone',
          filterable: true,
        },
        {
          text: 'email',
          value: 'email',
          filterable: true,
          visible: false,
        },
        {
          text: 'patientType',
          value: 'patientType',
          filterable: true,
        },
        {
          text: 'createdBy',
          value: 'created_by',
          filterable: true,
          visible: false,
        },
        {
          text: 'createdDate',
          value: 'created_at',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedDate',
          value: 'updated_at',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedBy',
          value: 'updated_by',
          filterable: true,
          visible: false,
        },
        {
          text: 'id',
          value: 'id',
          filterable: true,
          visible: false,
        },
        {
          text: 'externalId',
          value: 'external_id',
          filterable: true,
          visible: false,
        },
        {
          text: 'organization',
          value: 'organization',
          filterable: true,
          visible: false,
        },
      ];

      if (
        this.$can(this.permissions.actions.change, this.permissions.subjects.patient) ||
        this.hasAssignPermissions ||
        this.$can(this.permissions.actions.delete, this.permissions.subjects.patient)
      ) {
        columns.push({
          text: 'actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }

      return columns;
    },
    hasAssignPermissions() {
      return (
        this.$can(this.permissions.actions.change, this.permissions.subjects.patientActivityProtocol) ||
        this.$can(this.permissions.actions.change, this.permissions.subjects.patientCohort) ||
        this.$can(this.permissions.actions.change, this.permissions.subjects.patientHealthWorker) ||
        this.$can(this.permissions.actions.change, this.permissions.subjects.patientIotDevice)
      );
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    init: function () {
      this.getResidentActions();
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
    },

    getResidentActions: function () {
      var success = function (response) {
        this.showWaitModal = false;

        let rawResidents = response.data;

        this.residents = rawResidents.map((r) => {
          var result = Object.assign({}, r);

          result.country = r.country ? r.country.name : '';
          result.created_by = r.created_by ? r.created_by.username : '';
          result.gender = r.gender ? r.gender.name : '';
          result.language = r.language ? r.language.name : '';
          result.patientType = r.patientType ? r.patientType.name : '';
          result.updated_by = r.updated_by ? r.updated_by.username : '';
          result.roombed = r.roombed ? this.getRoombedText(r.roombed) : '';
          result.facility = r.facility ? r.facility.name : '';
          result.organization = r.organization ? r.organization.name : '';

          if (r.province?.country?.code === 'FR' && r.province.code) {
            result.province = `${r.province.code} - ${r.province.name}`;
          } else {
            result.province = r.province ? r.province.name : '';
          }

          return result;
        });
      };

      this.showWaitModal = true;
      this.getResidents(success, this.defaultErrorCallBack);
    },

    // deleteSelectedResident: function () {
    //   this.showDelete = false;
    //   this.showWaitModal = true;

    //   var success = function () {
    //     this.showWaitModal = false;
    //     this.init();
    //   };

    //   this.deleteResident(this.selectedResidentId, success, this.defaultErrorCallBack);
    // },

    setHealthcareProfessionalResidentId: function (residentId) {
      this.selectedResidentId = residentId;
      this.showAssignHealthcareProfessionalsModal = true;
    },

    setCommunicatorResidentId: function (residentId) {
      this.selectedResidentId = residentId;
      this.showAssignIOTModal = true;
    },

    setCohortResidentId: function (residentId) {
      this.selectedResidentId = residentId;
      this.showAssignCohortModal = true;
    },

    setDocResidentId: function (residentId) {
      this.selectedResidentId = residentId;
      this.showAssignDocModal = true;
    },

    goToUserActivitiesSelector: function (residentId) {
      this.selectedResidentId = residentId;
      this.showAssignActivityModal = true;
    },

    residentModalClose: function (refresh) {
      this.showEditModal = false;
      if (refresh) {
        this.init();
      }
    },

    editResidentAction: function (residentId) {
      this.selectedResidentId = residentId;
      this.showEditModal = true;
    },

    getRoombedText(roombed) {
      return roombed.bed ? `${roombed.room} - ${roombed.bed}` : roombed.room;
    },

    viewPatientMonitoringDetail(patientId) {
      this.$router.push({ name: 'PatientMonitoringGrid', params: { patientId: patientId } });
    },

    // deleteAction: function (id) {
    //   this.selectedResidentId = id;
    //   this.showDelete = true;
    // },
  },
};
</script>

<style scoped></style>
