import PatientMonitoringGrid from '@/components/PatientMonitoring/PatientMonitoringGrid';
import PatientMonitoringAlertRealtime from '@/components/PatientMonitoringAlertRealtime/PatientMonitoringAlertRealtime';
import PatientHome from './PatientHome';

const parsePatientMonitoringGridParams = (params, query) => ({
  patientId: params.patientId ? Number.parseInt(params.patientId, 10) : undefined,
  detailType: params.detailType,
  detailId: params.detailId ? Number.parseInt(params.detailId, 10) : undefined,
  notificationDatetime: query.notificationDatetime,
  alertTime: query.alertTime,
});

export default {
  moduleName: 'patient',
  routes: [
    {
      path: '/patient',
      name: 'PatientHome',
      component: PatientHome,
    },
    {
      path: '/patientmonitoring/:patientId?/:detailType?/:detailId?',
      name: 'PatientMonitoringGrid',
      component: PatientMonitoringGrid,
      props: (route) => parsePatientMonitoringGridParams(route.params, route.query),
    },
    {
      path: '/patientmonitoringalert',
      name: 'PatientMonitoringAlert',
      component: PatientMonitoringAlertRealtime,
    },
  ],
};
