import { StatusLevels } from './constants';

export default {
  created: function () {},

  methods: {
    getIconColor: function (code) {
      var color = null;

      switch (code) {
        case StatusLevels.NO:
          color = 'green';
          break;
        case StatusLevels.W4:
          color = 'green';
          break;
        case StatusLevels.W24:
          color = 'rgba(0, 0, 0, 0.54)';
          break;
        case StatusLevels.WA:
          color = 'orange';
          break;
        case StatusLevels.AL:
          color = 'red';
          break;
        case StatusLevels.DR:
          color = '#6fcf97';
          break;
        case StatusLevels.WE:
          color = '#F2BA4C';
          break;
        case StatusLevels.ERR:
          color = '#d8574c';
          break;
        case StatusLevels.CH:
          color = '#d8574c';
          break;
        default:
          color = 'rgba(0, 0, 0, 0.54)';
          break;
      }

      return color;
    },

    getAnamExecutionIcon: function (code) {
      let name = null;

      switch (code) {
        case StatusLevels.AL:
          name = 'mdi-alert-octagon';
          break;
        case StatusLevels.NO:
          name = 'mdi-checkbox-outline';
          break;
        case StatusLevels.WA:
        default:
          name = 'mdi-checkbox-blank-outline';
          break;
      }

      return name;
    },

    getAnamExecutionColor: function (code) {
      var color = null;

      switch (code) {
        case StatusLevels.AL:
          color = 'red';
          break;
        case StatusLevels.WA:
        case StatusLevels.NO:
        default:
          color = null;
          break;
      }

      return color;
    },

    getAnamAlertColor: function (code) {
      var color = null;

      switch (code) {
        case StatusLevels.WA:
          color = 'orange';
          break;
        case StatusLevels.AL:
          color = 'red';
          break;
        default:
          color = null;
          break;
      }

      return color;
    },

    getPrescriptionAlertColor: function (code) {
      var color = null;

      switch (code) {
        case StatusLevels.WA:
          color = 'orange';
          break;
        case StatusLevels.AL:
          color = 'red';
          break;
        default:
          color = null;
          break;
      }
      return color;
    },

    getIconName: function (code) {
      var name = null;

      switch (code) {
        case StatusLevels.NO:
        case StatusLevels.DR:
        case StatusLevels.WE:
          name = 'mdi-check-circle';
          break;
        case StatusLevels.W4:
          name = 'mdi-alert-circle-check';
          break;
        case StatusLevels.W24:
          name = 'mdi-sync-off';
          break;
        case StatusLevels.ERR:
        case StatusLevels.WA:
          name = 'mdi-alert';
          break;
        case StatusLevels.AL:
          name = 'mdi-alert-octagon';
          break;
        case StatusLevels.NF:
          name = 'mdi-minus-thick';
          break;
        default:
          name = '';
          break;
      }

      return name;
    },

    getAnamAlertIcon: function (code) {
      var name = null;

      switch (code) {
        case StatusLevels.NO:
          name = 'mdi-check';
          break;
        case StatusLevels.WA:
          name = 'mdi-alert';
          break;
        case StatusLevels.AL:
          name = 'mdi-alert-octagon';
          break;
        default:
          name = 'none';
          break;
      }

      return name;
    },
    getPrescriptionAlertIcon: function (code, allTaken) {
      var name = null;

      switch (code) {
        case StatusLevels.NO:
          name = allTaken ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline';
          break;
        default:
          name = 'mdi-alert-octagon';
          break;
      }

      return name;
    },
    getPrescriptionChildIcon: function (taken, alert_triggered) {
      let name = null;

      if (taken) {
        name = 'mdi-checkbox-outline';
      } else {
        name = alert_triggered ? 'mdi-alert-octagon' : 'mdi-checkbox-blank-outline';
      }

      return name;
    },
    getPrescriptionChildColor: function (alert_triggered) {
      let color = null;

      if (alert_triggered) {
        color = 'red';
      } else {
        color = null;
      }

      return color;
    },

    getFDEAlertColor: function (code) {
      var color = null;

      switch (code) {
        case StatusLevels.AL:
          color = 'red';
          break;
        default:
          color = null;
          break;
      }

      return color;
    },
  },
};
